import { useTheme } from "@emotion/react";
import { Container, ImageList, ImageListItem, Typography, useMediaQuery } from "@mui/material"


export const Instalaciones = () => {

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    const itemData = [
        {
            img: '/place/1.jpg',
        },
        {
            img: '/place/2.jpg',
        },
        {
            img: '/place/3.jpg',
        },
        {
            img: '/place/4.jpg',
        },
        {
            img: '/place/5.jpg',
        }
    ];

    return <Container  sx={{mb: 8}}>
        <Typography variant="h3" sx={{mb:4}} color="white">Instalaciones</Typography>
        <Typography variant="body1" sx={{mb:8}} color="white">Sumérgete en la elegancia de nuestros espacios, desde nuestras salas de masajes diseñadas con esmero hasta los rincones acogedores que invitan a la relajación. La iluminación tenue y la arquitectura cuidadosamente seleccionada crean un ambiente íntimo y sofisticado. Descubre un mundo de confort en nuestras instalaciones, donde cada rincón ha sido concebido para proporcionarte una experiencia de bienestar inigualable</Typography>
        <ImageList variant="masonry" sx={{
    columnCount: {
      xs: '2 !important',
      sm: '2 !important',
      md: '3 !important',
      lg: '4 !important'
    },
  }} gap={8}>
        {itemData.map((item) => (
            <ImageListItem key={item.img}>
                <img
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt="Instalaciones"
                    style={{borderRadius: 12}}
                    loading="lazy"
                />
            </ImageListItem>
        ))}
    </ImageList></Container>
}