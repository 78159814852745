import { Box, Container, Grid, Typography } from "@mui/material";
import img1 from '../assets/images/h3-icon-img1.png';
import img2 from '../assets/images/h3-icon-img2.png';
import img3 from '../assets/images/h3-icon-img3.png';

export const ThreeColSection = () => {
    return <Box color="black" padding={3} marginTop={5} marginBottom={6}>
        <Container>
            <Grid container spacing={6}>
                <Grid item md={4} style={{ backgroundImage: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                    <Typography variant="h4" color="secondary" sx={{ mb: 4, mt: 8.8, fontFamily: "'go3v2', cursive" }}>Masajes Orientales</Typography>
                    <Typography variant="body1" color="white" lineHeight={2}>Sumérgete en el encanto ancestral de nuestros masajes orientales, donde la sabiduría milenaria se fusiona con el arte del bienestar.</Typography>

                </Grid>
                <Grid item md={4} style={{ backgroundImage: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                    <Typography variant="h4" color="secondary" sx={{ mb: 4, mt: 8.8, fontFamily: "'go3v2', cursive" }}>Modernas instalaciones</Typography>
                    <Typography variant="body1" color="white" lineHeight={2}>En nuestro oasis de tranquilidad, cada tratamiento está diseñado para proporcionarte un escape rejuvenecedor, restaurando tu equilibrio y promoviendo una sensación de total bienestar</Typography>

                </Grid>
                <Grid item md={4} style={{ backgroundImage: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>

                    <Typography variant="h4" color="secondary" sx={{ mb: 4, mt: 14, fontFamily: "'go3v2', cursive" }}>Cerca de ti</Typography>
                    <Typography variant="body1" color="white" lineHeight={2}>Te esperamos en una ubicación privilegiada, ofreciéndote fácil acceso para que disfrutes de una pausa rejuvenecedora en medio del bullicio de la ciudad</Typography>
                </Grid>
            </Grid>
        </Container>
    </Box>
}