import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsappSquare, faSquareWhatsapp, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Button from '@mui/material/Button';
//import logo from "../assets/images/logohuahao.svg";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement ;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    color: trigger ? "primary" : "transparent"
  });
}

interface Props2 {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement | React.ReactElement[];
  }

export default function ElevateAppBar(props: Props2) {
  return (
    <React.Fragment>
      
      <ElevationScroll {...props}>
        <AppBar color="transparent">
          <Toolbar>
          <img src="/images/logo3.svg" alt="Hue Hao Yue Yuan | Centro de masajes" style={{height: "26px", marginTop: "6px", marginBottom: "6px"}}/>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <IconButton href="https://wa.link/kffk9v" target="_BLANK" size="large" onClick={() => {}} sx={{ p: 0, mr: 2, mt: "-8px" }}><FontAwesomeIcon color="white" icon={faWhatsapp} /></IconButton>
              <IconButton href="tel:+34605245681" size="large" onClick={() => {}} sx={{ p: 0, mr: 2, mt: "-8px" }}><FontAwesomeIcon size="xs" color="white" icon={faPhone} /></IconButton>  
              <Typography variant="h6" component="span" color="white" sx={{ml: 1, height: "100%"}}>
              605 245 681
            </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
        {props.children}
    </React.Fragment>
  );
}