import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbYRhxcdfUrvph3pzHWsNB9I3gLYtesGo",
  authDomain: "huahaoyueyuan-392fc.firebaseapp.com",
  projectId: "huahaoyueyuan-392fc",
  storageBucket: "huahaoyueyuan-392fc.appspot.com",
  messagingSenderId: "1021378479222",
  appId: "1:1021378479222:web:5db6cd332aa7ddac0b0b57",
  measurementId: "G-C1H0RRZT9K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#231711"
    },
    secondary: {
      main: "#ffdc81"
    }
  },
  typography: {
    fontFamily: "'Noto Sans', sans-serif;",
  },
});


root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
