// @mui material components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


// Images
import bgImage from "../assets/images/top4.jpeg";

function HeaderOne() {
  return (

      <Box
        display="flex"
        alignItems="center"
        minWidth="100%"
        minHeight="100vh"
        sx={{
          //backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.5), rgba(gradients.dark.state, 0.5))}, url(${bgImage})`,
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginTop: "-64px"
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={7} flexDirection="column" justifyContent="left" alignContent="end">
            <Typography
              variant="h4"
              color="white"
              align="left"
              mb={3}
              mt={12}
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  fontSize: "h5",
                },
              })}
            >
              Descubre nuestros exquisitos masajes orientales en el corazón de Barcelona
            </Typography>
            <Stack direction="row" spacing={1} mt={3}>
              <Button href="/#tratamientos" variant="contained" color="secondary" size="large">Ver detalles</Button>
            </Stack>
          </Grid>
        </Container>
      </Box>

  );
}

export default HeaderOne;